@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
  margin-bottom: 15px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightBold);

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.label {
  composes: marketplaceLandingPageText from global;
  color: var(--colorGrey700);
  font-size: 15px;
  font-weight: 400;
}

.labelSelected {
  composes: marketplaceLandingPageText from global;
  color: var(--marketplaceColor);

  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {

  font-size: 14px;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);
  padding: 2px 0 4px 2px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 2px;
    margin-top: 2px;
  }
}

.plain {
  width: 100%;
  display: none;

  @media (--viewportMedium) {
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}

.searchInputHolder {
  position: relative;
}

.searchInput {
  min-height: 40px;
  margin: 10px 0 5px;
  padding: 2px 25px 2px 10px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    min-height: 20px;
  }
}

.searchInputButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  line-height: 1;
  cursor: pointer;
}
