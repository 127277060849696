.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: none;
  flex-direction: row;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    display: flex;
  }
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  composes: marketplaceLandingPageText from global;
  font-size: 15px;
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;

}

.sortyBy {
  composes: marketplaceLandingPageText from global;
  font-size: 15px;
  padding: 10px 8px 8px 0;
}